@import '/src/index.scss';

.serving {
    margin-top: 50px;

    box-sizing: border-box;
    width: 100%;
    max-width: 1440px;
    padding-inline: 70px;
    
    @media (max-width: 480px) {
        padding-inline: 40px;
    }
}


.servingBlock {
    box-sizing: border-box;
    width: 100%;

    display: flex;
}

.h2 {
    font-size: 40px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 400;
    color: $main_color;

    @media (max-width: 480px) {
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
    }
}

.servingMain {
    @media (min-width: 1024px) {
        width: 50%;      
    }
}

.servingList {
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 100px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10%;
    }
}

.servingImg {
    @media (min-width: 1024px) {
        margin-top: 16px;
        width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;   
    }
    
    @media (max-width: 480px) {
        display: none;
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        display: none;
    }
}

.imgWrapper {
    height: 180px;
    width: 100%;
    
    overflow: hidden;
    border-radius: 20px 0 0 20px;
}

.img {
    width: 100%;
    margin-top: -45px;
}

.rectangle {
    width: 50px;
    height: 180px;
    background-color: #439BCB;
}