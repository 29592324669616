@import '/src/index.scss';

.header {
    box-sizing: border-box;
    width: 100%;
}


.headerWrapper {
    width: 100%;

    display: flex;
    justify-content: center;

    @media (min-width: 1024px) {
        background-image: linear-gradient(90deg, white 17%, rgba(255, 255, 255, 0) 100%), url('../../shared/picHeader.webp');
        background-size: cover;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        background-image: linear-gradient(90deg, white 17%, rgba(255, 255, 255, 0) 100%), url('../../shared/picHeader.webp');
        background-size: cover;        
    }
}

.headerText {
    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 1024px) {
        box-sizing: border-box;
        width: 100%;
        max-width: 1440px;
        padding-inline: 70px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        box-sizing: border-box;
        width: 100%;
        max-width: 1440px;
        padding-inline: 70px;        
    }
}

.h1 {
    color: $main_color;
    font-weight: 600;
    margin-bottom: 0;

    @media (max-width: 480px) {
        margin-top: 24px;
        width: 100%;
        font-size: 45px;
        text-align: center; 
    }
    @media (min-width: 1024px) {
        margin-top: 134px;
        width: 605px;
        font-size: 85px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 80px;
        max-width: 605px;
        font-size: 55px;        
    }
}

.textMinor {
    font-weight: 500;
    color: $main_color;
    @media (max-width: 480px) {
        margin-top: 34px;
        width: 250px;
        text-align: center;
        font-size: 20px;
    }
    @media (min-width: 1024px) {
        margin-top: 26px;
        width: 320px;
        font-size: 30px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 20px;
        width: 320px;
        font-size: 25px;        
    }
}

.button {
    background-color: $main_color;

    color: $text_minor_color;
    font-family: "Roboto";

    border: 0;

    padding: 0;

    cursor: pointer;
    &:active {
        background-color: $active_color;
    }

    @media (max-width: 480px) {  
        margin-top: 24px;
        height: 48px;
        border-radius: 9px;

        font-size: 22px;
    }

    @media (min-width: 1024px) {
        margin-top: 66px;
        margin-bottom: 60px;
        height: 75px;
        border: 0;
        border-radius: 14px;

        font-size: 35px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 66px;
        margin-bottom: 60px;
        height: 75px;
        border: 0;
        border-radius: 14px;

        font-size: 28px;  
    }
}

.a {
    box-sizing: border-box;
    color: $text_minor_color;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media (max-width: 480px) {
        padding-inline: 20px;
    }
    @media (min-width: 1024px) {
        padding-inline: 24px;        
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        padding-inline: 22px;
    }
}

.callWrapper {
    margin-top: 22px;
    margin-left: -20px;
    @media (min-width: 480px) {
        display: none;
    }
}