.servingItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    font-weight: 400;
    text-transform: uppercase;
    color: #03227A;
    white-space: nowrap;

    @media (min-width: 1024px) {
        margin-top: 16px;
        font-size: 15px;
    }

    @media (max-width: 480px) {
        margin-top: 18px;
        font-size: 14px;
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 16px;
        font-size: 15px;
    }
}