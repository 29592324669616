@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$main_color: #255194;
$active_color: #1f457d;
$minor_color: #001ea2;
$text_minor_color: white;
$bg_color: #E9F5FF;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @media (max-width: 480px) {
    
  // }
  // margin-right: calc(100vw - 100%);
}

.gap-12 {
  width: 100%;
  height: 40px; // ОБРАТИ ВНИМАНИЕ ВРЕМЕННОЕ РЕШЕНИЕ
}