@import '/src/index.scss';


.logoWrapper {
    width: 100%;
    background-color: $bg_color;
    display: flex;
    justify-content: center;


    @media (min-width: 1024px) {
        position: fixed;
        top: 0;

        z-index: 1;

        transition: transform 0.5s ease-in-out;
    }
}

@media (min-width: 1024px) {
    .hidden {
        transform: translateY(-100%);
    }

    .visible {
        transform: translateY(0);
    }
}



.logo {
    box-sizing: border-box;
    width: 100%;
    max-width: 1440px;

    display: inline-flex;
    justify-content: space-between;

    @media (min-width: 1024px) {
        height: 76px;
        padding-inline: 70px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        height: 76px;        
        padding-inline: 20px;
    }
    @media (max-width: 480px) {
        height: 92px;
        padding-inline: 20px;
    }
}

.logoInline {
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.svg {
    @media (min-width: 1024px) {
        width: 60px;
        height: 60px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        width: 60px;
        height: 60px;
    }
    @media (max-width: 480px) {
        width: 70px;
        height: 70px;
    }
}

.logoText {
    font-weight: 500;
    color: $main_color;
    padding-top: 3px;

    @media (max-width: 480px) {
        font-size: 28px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        font-size: 25px;        
    }
    @media (min-width: 1024px) {
        font-size: 25px;
    }
}

.callWrapper {
    height: 100%;
    display: inline-flex;
    align-items: center;
    
    @media (max-width: 480px) {
        display: none;
    }
}