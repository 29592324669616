@import '/src/index.scss';

.service {
    margin-top: 50px;

    box-sizing: border-box;
    width: 100%;

    @media (min-width: 1024px) {
        max-width: 1440px;        
        padding-inline: 70px;
    }

    @media (max-width: 480px) {
        padding-inline: 44px;
    }
    
    @media (min-width: 480px) and (max-width: 1024px) {
        padding-inline: 70px;
    }

    h2 {
        margin: 0;
        font-size: 40px;
        font-weight: 400;
        text-transform: uppercase;
        color: $main_color;

        @media (max-width: 480px) {
            text-align: center;
        }
    }

    &Grid {
        @media (min-width: 1024px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        @media (min-width: 480px) and (max-width: 1024px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .gridColumn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .wrapper {
        display: inline-flex;
        width: 100%;
    }

    &Column {
        width: 100%;
        @media (min-width: 1024px) {
            width: 50%;            
        }
    }
}


.serviceImg {
    @media (min-width: 1024px) {
        margin-top: 16px;
        width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;   
    }
    
    @media (max-width: 480px) {
        display: none;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        display: none;
    }
}

.imgWrapper {
    height: 350px;
    width: 100%;
    
    overflow: hidden;
    border-radius: 20px 0 0 20px;

    display: flex;
    justify-content: flex-end;
}

.img {
    height: 100%;
}

.rectangle {
    width: 50px;
    height: 350px;
    background-color: #439BCB;
}