@import '/src/index.scss';

.reviews {
    box-sizing: border-box;

    margin-top: 50px;

    width: 100%;
    max-width: 1440px;

    @media (min-width: 1024px) {
        padding-inline: 70px;
    }

    @media (max-width: 480px) {
        padding-inline: 44px;
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        padding-inline: 70px;
    }
}

.title {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 400;
    color: $main_color;

    @media (max-width: 480px) {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }
}


@media (min-width: 1024px) {
    .sliderWrapperMobile {
        display: none;
    }
}

@media (max-width: 480px) {
    .sliderWrapperDesctop {
        display: none;
    }
}

@media (min-width: 480px) and (max-width: 1024px) {
    .sliderWrapperDesctop {
        display: none;
    }
}


.sliderWrapperDesctop {
    width: 100%;
}

.sliderWrapperMobile {
    width: 100%;
}

.slick-arrow {
    background-color: black
}