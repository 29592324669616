.list {
    h3 {
        margin-top: 20px;
        margin-bottom: 8px;

        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: #03227A;
        
    }

    .map {
        display: flex;
        flex-direction: column;
        gap: 8px;

    }
}