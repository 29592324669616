@import '/src/index.scss';

.about {
    box-sizing: border-box;
    width: 100%;
    max-width: 1440px;

    @media (min-width: 1024px) {
        padding-inline: 70px;
    }
    @media (max-width: 480px) {
        padding-inline: 44px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        padding-inline: 70px;
    }
}

.aboutRow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: 1024px) {
        gap: 40px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        gap: 40px;
        flex-direction: column;
    }
    @media (max-width: 480px) {
        gap: 20px;
        flex-direction: column;
    }
}

.titleAbout {
    width: 100%;
    margin-top: 55px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 40px;
    color: $main_color;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
        margin-bottom: 30px;
    }
    @media (max-width: 480px) {
        text-align: center;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        
    }
}