@import '/src/index.scss';

.reviewWrapper {
    @media (min-width: 1024px) {
        box-sizing: border-box;
        width: 100%;
        padding-inline: 15px;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        width: 100%;
    }
}

.review {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    background-color: $bg_color;

    border: 0;
    border-radius: 20px;

    padding: 12px 20px 15px;

    @media (min-width: 1024px) {
        height: 215px;
    }

    @media (max-width: 480px) {
        height: 163px;
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        height: 180px;
    }

    .reviewBlock {
        display: flex;
        align-items: center;

        img {
            height: 45px;
            width: 45px;
            border: 0;
            border-radius: 50%;
            background-color: #3B8DCB;
        }

        .reviewName {
            margin-top: 3px;
            margin-left: 10px;
            font-size: 15px;
            color: $main_color;

            display: flex;
            flex-direction: column;
        }
    }

    .reviewText {
        margin: 0;
        margin-top: 6px;
        height: 100%;

        @media (min-width: 1024px) {
            font-size: 14px;   
        }

        @media (max-width: 480px) {
            font-size: 10px;   
        }

        @media (min-width: 480px) and (max-width: 1024px) {
            font-size: 14px;
        }

        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;


        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(to bottom, #000, #000 70%, rgba(0, 0, 0, 0));
    }

    a {
        margin-top: 10px;
        max-width: fit-content;
        font-size: 13px;
        color: black;

        &:active, &:hover {
            color: #9200EC;
        }
        
        @media (max-width: 480px) {
            font-size: 10px;   
        }

        svg {
            margin-left: 2px;
        }
    }
}