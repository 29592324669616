@import '/src/index.scss';

.footer {    
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main_color;
    color: $text_minor_color;

    @media (min-width: 1024px) {
        height: 60px;
    }
}