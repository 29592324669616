@import '/src/index.scss';

.contactWrapper {
    margin-top: 70px;
    width: 100%;
    background-color: $bg_color;

    display: flex;
    justify-content: center;
}

.contact {
    width: 100%;

    @media (min-width: 1024px) { 
        box-sizing: border-box;
        max-width: 1440px;
        padding-inline: 70px;
    }

    @media (max-width: 480px) {
        padding-inline: 20px;
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        padding-inline: 70px;
    }
}

.titleContact {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 400;
    color: $main_color;

    @media (max-width: 480px) {
        margin-top: 30px;
        text-align: center;
    }

    @media (min-width: 1024px) {
        margin-top: 40px;
    }
}

.contactForm {
    width: 100%;
    box-sizing: border-box;
    
    .inputArea {
        padding: 10px 18px;
        resize: vertical;
    }

    @media (max-width: 480px) {
        margin-top: 24px;
        display: flex;
        flex-direction: column;

        .inputArea {
            height: 120px;
        }
    }

    @media (min-width: 1024px) {
        margin-top: 8px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        row-gap: 16px;

        .fieldArea {
            grid-column-start: 1;
            grid-column-end: 4;

            .inputArea {
                height: 90px;
            }
        }
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .inputArea {
            height: 120px;
        }
    }
}

.field {
    @media (max-width: 480px) {
        margin-top: 23px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 20px;
    }
}

.field:first-child {
    margin-top: 0;
}

.label {
    font-size: 18px;
    color: $main_color;
}

.input {
    margin-top: 4px;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 0 18px;
    background-color: transparent;

    border: solid #386E97 2px;
    border-radius: 8px;

    font-size: 20px;
    font-family: "Roboto";

	transition: border-color 0.2s;

    &:focus {
        border-color: $minor_color;
        outline: none;
    }

    &error {
        border-color: red;
    }
}


.button {
    margin-bottom: 40px;

    color: $text_minor_color;
    background-color: $main_color;

    border: 0;
    border-radius: 8px;

    font-family: "Roboto";

    cursor: pointer;

    &:disabled {
        background-color: #25519488;
    }

    &:active {
        background-color: $active_color;
    }

    @media (max-width: 480px) {
        margin-top: 22px;
        height: 38px;
        font-size: 20px;
    }

    @media (min-width: 1024px) {
        margin-top: 11px;
        height: 51px;
        width: 373px;
        font-size: 30px;

        grid-row-start: 4;
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 11px;
        height: 51px;
        max-width: 373px;
        font-size: 30px;        
    }
}

.inputFile {
    margin-top: 12px;

    color: #03227A;

    @media (min-width: 1024) {
        margin-top: 22px;
    }
}