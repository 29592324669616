.item {
    display: inline-flex;
    align-items: center;

    gap: 10px;

    a {
        margin-top: -2px;
        font-size: 15px;
        text-transform: capitalize;
        color: #03227A;

        &:visited {
            color: #03227A;
        }

        &:active, &:hover {
            color: #9200EC;
        }
    }
}