@import '/src/index.scss';

.aboutblock {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: flex-start;

    @media (min-width: 1024px) {
        flex-direction: column;
        gap: 30px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }

    @media (min-width: 480px) and (max-width: 1024px) {
        flex-direction: column;
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }
}

.imgWrapper {
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1024px) {
        height: 200px;
        width: 100%;
    }
    @media (max-width: 480px) {
        height: 275px;
        height: 100%;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        max-height: 275px;
        width: 100%;
    }
}

.img {
    @media (min-width: 1024px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        width: 100%;
    }
}

.text {
    margin: 0;
    text-align: center;

    @media (min-width: 1024px) {
        margin-top: 10px;
        width: 100%;
        // text-align: left;
        font-size: 18px;
    }
    @media (max-width: 480px) {
        margin-top: 20px;
        font-size: 17px;
    }
    @media (min-width: 480px) and (max-width: 1024px) {
        margin-top: 20px;
        font-size: 20px;
    }
}