.a {
    color: #03227A;
    font-size: 20px;
    font-family: "Roboto";
    
    display: inline-flex;
    align-items: center;
    gap: 15px;

    &:hover {
        color: #9200EC;
    }

    &:active {
        color: #9200EC;
    }
}

.call {
    display: inline-flex;
    align-items: center;
    gap: 15px;
}